var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"GroupEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editGroup($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"state":errors.length > 0 ? false : null,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},on:{"input":_vm.onOrgChange},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Owner","label-for":"Owner"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Owner")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"group_owner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.users,"state":errors.length > 0 ? false : null},model:{value:(_vm.group_owner_id),callback:function ($$v) {_vm.group_owner_id=$$v},expression:"group_owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Group Name","rules":{ regex: /^[a-zA-Z0-9\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"group_name","state":errors.length > 0 ? false : null,"name":"group_name"},model:{value:(_vm.group_name),callback:function ($$v) {_vm.group_name=$$v},expression:"group_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Group Description","label-for":"Group Description"}},[_c('b-form-textarea',{attrs:{"id":"group_description","name":"group_description"},model:{value:(_vm.group_description),callback:function ($$v) {_vm.group_description=$$v},expression:"group_description"}})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.group_name === '',"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }