<template>
  <div>
    <b-card-code>
      <div class="d-flex justify-content-end">
        <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
          >Back</b-button
        >
      </div>
      <validation-observer ref="GroupEditForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="editGroup">
          <!-- Organization-->

          <b-form-group
            v-if="is_staff"
            label="Organization"
            label-for="Organization"
          >
            <validation-provider
              #default="{ errors }"
              name="organization"
              rules="required"
            >
              <v-select
                v-model="organization"
                label="name"
                placeholder="--Select Organization--"
                :options="organizations"
                :state="errors.length > 0 ? false : null"
                @input="onOrgChange"
                autocomplete
                :reduce="(organization) => organization.tenant_uid"
                disabled
              />
              <!-- <v-select
                v-model="organization"
                label="text"
                placeholder="--Select Organization--"
                :options="organizations"
                :state="errors.length > 0 ? false : null"
                @input="onOrgChange"
                autocomplete
                :reduce="(organization) => organization.value"
                disabled
              /> -->
              <!-- <b-form-select
                v-model="organization"
                :options="organizations"
                :state="errors.length > 0 ? false : null"
                @change="onOrgChange"
              ></b-form-select> -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Owner-->
          <b-form-group v-if="is_staff" label="Owner" label-for="Owner">
            <template #label>
              <span>Owner</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="group_owner_id"
              rules="required"
            >
              <b-form-select
                v-model="group_owner_id"
                :options="users"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Asset Name -->
          <b-form-group label="Name" label-for="Name">
            <template #label>
              <span>Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Group Name"
              :rules="{ regex: /^[a-zA-Z0-9\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }"
            >
              <b-form-input
                id="group_name"
                v-model="group_name"
                :state="errors.length > 0 ? false : null"
                name="group_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Asset Nick Name -->
          <b-form-group label="Group Description" label-for="Group Description">
            <b-form-textarea
              id="group_description"
              v-model="group_description"
              name="group_description"
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid || group_name === ''"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      group_name: "",
      group_owner_id: "",
      group_description: "",
      // organization: "",
      organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: [{ value: null, text: "--Select Organization--" }],
      users: [{ value: null, text: "--Select Owner--" }],
      dir: "ltr",
      page_title: "Add assets to group",
      assets: [],
      asset_options: [{ value: null, text: "--Select Asset--" }],
      isloading: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      var self = this;
      var selfi = this;
      // if (this.is_staff) {
      //   const o_options = {
      //     method: "GET",
      //     headers: { "content-type": "application/json" },
      //     url:
      //       process.env.VUE_APP_BASEURL +
      //       "organization/organization/get-all-org/",
      //   };
      //   this.$http(o_options).then((res) => {

      //     res.data.map(function (value, key) {
      //       let org = {
      //         value: res.data[key].org_id,
      //         text: res.data[key].org_name,
      //       };

      //       self.organizations.push(org);
      //     });
      //   });
      // }

      var selfi = this;
      // Also load existing assets and details of the group
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/group/" + this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.group_name = res.data.group_name;
        this.group_description = res.data.group_description;
        this.group_owner_id = res.data.group_owner_id;
        let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
        if (this.is_staff) {
          url = url + "?q_org_id=" + this.organization;
        }
        const u_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(u_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let user = {
              value: res.data[key].id,
              text: res.data[key].full_name,
            };
            // console.log(res.data[key].profile.organization)
            self.users.push(user);
          });
        });
        // const a_options = {
        //     method: 'GET',
        //     headers: { 'content-type': 'application/json' },
        //     url: process.env.VUE_APP_BASEURL+'asset/org-asset?org_id='+this.organization,
        //     }
        //     this.$http(a_options).then( ( res ) => {
        //         res.data.map(function(value, key) {
        //             let a = {
        //                 value: res.data[key].asset_id,
        //                 text: res.data[key].asset_nickname
        //                 };
        //             // console.log(res.data[key].profile.organization)
        //             self.asset_options.push(a)
        //         } )
        //     });
      });
    },
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
    },
    addAssets: function () {
      this.$refs.GroupAssetsAddForm.validate().then((success) => {
        let data = {
          group_id: this.$route.params.id,
          assets: this.assets,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/group/add-assets/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            this.$router.push({ name: "Groups" });
          }
        });
      });
    },
    editGroup: function () {
      this.$refs.GroupEditForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          console.log("success");
          if (!this.is_staff)
            this.group_owner_id = this.$store.state.app.user.id;
          let data = {
            group_name: this.group_name,
            group_description: this.group_description,
            group_owner_id: this.group_owner_id,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/group/" +
              this.$route.params.id +
              "/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.group_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Edited Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: "Groups" });
              this.isloading = false;
            }
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
